<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-row class="justify-center">
      <div class="col-12">
        <v-row class="justify-center">
          <div class="col-12 col-sm-6 col-md-4">
            <v-card class="mx-auto" @click="addToken('USDC')">
              <v-list-item two-line>
                <v-list-item-avatar size="75" color="cyan lighten-1">
                  <img width="50px" src="@/assets/usdc.png" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    {{ sixFormat(USDCBalance) }} USDC
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-card class="mx-auto">
              <v-list-item two-line>
                <v-list-item-avatar size="75" color="blue">
                  <img width="50px" src="@/assets/percent-modified.png" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    {{ (1 / (currentRate / 1e6)).toFixed(2) }} $USDC per $CARN
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-row>
        <v-row class="justify-center">
          <div class="col-12 col-sm-6 col-md-4">
            <v-card class="mx-auto" @click="addToken('CARN')">
              <v-list-item two-line>
                <v-list-item-avatar size="75" color="green">
                  <img width="50px" src="@/assets/carn_logo.png" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    {{ twelveFormat(CARNBalance) }} $CARN
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-card class="mx-auto">
              <v-list-item two-line>
                <v-list-item-avatar size="75" color="purple darken-2">
                  <img width="50px" src="@/assets/remaining_carn.png" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    {{ twelveFormat(maxSupply - totalSupply, 0) }} Remaining
                    $CARN
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-row>
        <v-row v-show="getUserAddress" class="justify-center">
          <div class="col-12 col-sm-6">
            <v-card class="mx-auto text-center">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    <h3 id="increaseInterval">
                      0 Days 0 Hours 0 Minutes 0 Seconds
                    </h3>
                  </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next rate decrease.
                  </h4>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-row>
        <v-row class="justify-center">
          <div class="col-12 col-sm-6">
            <v-card class="pa-5">
              <h1 class="grey--text text--darken-2">$CARN Token Booth</h1>
              <h4 class="font-weight-regular">
                The rate started off at 1 $USDC per 1 $CARN and increases 5%
                every week.
              </h4>
              <v-divider class="my-5"></v-divider>
              <v-text-field
                outlined
                type="text"
                class="mt-3"
                v-model="USDCAmount"
                label="Enter USDC Amount"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    @click="USDCAmount = sixFormat(USDCBalance, 6)"
                    small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                @click="onUSDCApprove()"
                block
                x-large
                color="yellow"
                class="py-5"
                :disabled="!getUserAddress || isLoading || walletChainID==369"
              >
                {{
                  walletChainID == 1 || walletChainID == 5
                    ? isUSDCApproved
                          ? `BUY  ${
                          Number(USDCAmount)
                            ? twelveFormat(USDCAmount * 1000000 * currentRate)
                            : ""
                        }  CARN`
                      : "Approve USDC"
                      : "You can only buy CARN from the token Booth on ETHEREUM"
                }}
              </v-btn>
            </v-card>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "CarnTicketBooth",
  data() {
    return {
      USDCAmount: 0,
      USDCBalance: 0,
      CARNBalance: 0,
      isUSDCApproved: false,

      nextHike: 0,
      currentRate: 0,
      clearIntervalX: null,
      maxSupply: 0,
      totalSupply: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    readValues() {
      let address = this.getUserAddress;
      Promise.all([
        this.getCARNInstance.methods.nextHike().call(),
        this.getCARNInstance.methods.currentRate().call(),
        this.getCARNInstance.methods.MAX_SUPPLY().call(),
        this.getCARNInstance.methods.totalSupply().call(),
        this.getUSDCInstance.methods.balanceOf(address).call(),
        this.getCARNInstance.methods.balanceOf(address).call(),
        this.getUSDCInstance.methods
          .allowance(address, this.CARN_ADDRESS)
          .call(),
      ]).then(
        ([
          nextHike,
          currentRate,
          maxSupply,
          totalSupply,
          USDCBalance,
          CARNBalance,
          isUSDCApproved,
        ]) => {
          console.log("nextHike:", nextHike);
          console.log("currentRate:", currentRate);
          console.log("maxSupply:", maxSupply);
          console.log("totalSupply:", totalSupply);
          console.log("USDCBalance:", USDCBalance);
          console.log("CARNBalance:", CARNBalance);
          console.log("isUSDCApproved:", isUSDCApproved);

          this.nextHike = nextHike;
          this.currentRate = currentRate;
          this.USDCBalance = USDCBalance;
          this.CARNBalance = CARNBalance;
          this.maxSupply = maxSupply;
          this.totalSupply = totalSupply;
          this.isUSDCApproved = isUSDCApproved > 0 ? true : false;
          clearInterval(this.clearIntervalX);
          this.counter();
        }
      );
    },
    counter() {
      let countDownDate = this.nextHike * 1000;

      this.clearIntervalX = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
            "increaseInterval"
          ).innerHTML = `0 Days 0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
            "increaseInterval"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    onUSDCApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      if (this.isUSDCApproved) {
        this.onBuy();
        return;
      }
      this.SET_LOADING(true);
      this.getUSDCInstance.methods
        .approve(this.CARN_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("USDC Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("USDC Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("USDC Approve transaction has Failed");
        });
    },
    onBuy() {
      if (!this.USDCAmount) {
        this.$toasted.show("Enter USDC Amount");
        return;
      }
      if (
        Number(this.USDCAmount) > Number(this.sixFormat(this.USDCBalance, 6))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      this.SET_LOADING(true);
      this.getCARNInstance.methods
        .buyCARN(this.sixToWei(this.USDCAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("CARN Buy transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("CARN Buy transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("CARN Buy transaction has Failed");
        });
    },
    clearForm() {
      this.USDCAmount = 0;
    },
  },
  watch: {
    getUserAddress() {
      if (this.getUserAddress) this.readValues();
    },
  },
};
</script>
<style>
.v-main.CarnTicketBooth .v-main__wrap {
  background-image: url("../assets/10.png");
  background-size: 80%;
  background-position: center;
}
</style>
