<template>
  <div>
    <v-row>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto" @click="addToken('Hex')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="purple darken-2">
              <img width="50px" src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(HEXBalance) }}
                $HEX
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="green">
              <img width="50px" src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }}
                $CARN
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row v-show="getUserAddress" class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card class="mx-auto text-center">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="demo">0 Days 0 Hours 0 Minutes 0 Seconds</h3>
              </v-list-item-title>
              <h4 class="font-weight-regular">until the {{ text }} is Over.</h4>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12 col-md-6">
        <v-card
            :class="this.state === 0 ? '' : 'bg-grey'"
            class="pa-3 pa-sm-5"
            style="height: 100%"
        >
          <h1 class="grey--text text--darken-2">9 Day Reload Period</h1>
          <h4 class="font-weight-regular">
            9 days to withdraw your HEX Rewards and then stake again for the next staking session
          </h4>
          <v-divider class="my-5"></v-divider>
          <v-card outlined class="pa-5 my-5">
            <h3 class="font-weight-regular">
              Withdraw your share of the HEX Rewards from the last Hex Stake
            </h3>

            <v-btn
                @click="onClaimReward"
                block
                x-large
                color="yellow"
                class="py-5 mt-5"
                :disabled="
                !getUserAddress || isLoading || Number(asicDeposits.sessionId) === currentSessionId || !Number(asicDeposits.amount)
              "
            >
              Withdraw HEX Rewards
            </v-btn>
          </v-card>
          <v-card outlined class="pa-5 my-5">
            <v-text-field
                outlined
                type="text"
                v-model="HEXDepositAmount"
                label="Enter HEX Deposit Amount"
            >
              <template v-slot:append>
                <v-btn
                    text
                    color="primary"
                    @click="HEXDepositAmount = eightFormat(HEXBalance,3)"
                    small
                >
                  MAX
                </v-btn>
              </template>
            </v-text-field>
            <v-btn
                @click="onASICApprove()"
                block
                x-large
                color="yellow"
                class="py-5"
                :disabled="
                !getUserAddress || isLoading || Number(asicDeposits.sessionId) > currentSessionId || (!!Number(asicDeposits.amount) && Number(asicDeposits.sessionId) !== currentSessionId )
              "
            >
              {{ isASICApproved ? "Deposit HEX" : "Approve HEX" }}
            </v-btn>
            <small>10 CARN PER RIDE</small><br />
<!--            <small>Note: You do NOT get your ASIC back, ever.</small><br />-->
<!--            <small-->
<!--            >Note: With this deposit, you will only get $PLSB for THIS mining session, NOT-->
<!--              future sessions</small-->
<!--            >-->
          </v-card>




        </v-card>
      </div>
      <div class="col-12 col-md-6">
        <v-card
            :class="this.state === 1 ? '' : 'bg-grey'"
            class="pa-3 pa-sm-5"
            style="height: 100%"
        >
          <h1 class="grey--text text--darken-2">60 Day Hex Stake</h1>
          <h4 class="font-weight-regular">
            60 Days where the communities HEX goes to work.
          </h4>
          <v-divider class="my-6"></v-divider>

          <h3 class="my-5">
            You Deposited: {{ (Number(asicDeposits.sessionId) > currentSessionId - 2)? eightFormat(asicDeposits.amount) : 0}} $HEX
          </h3><!--Number(asicDeposits.sessionId) === currentSessionId - 1 ? -->
          <h3 class="my-5" v-show="state===0">
            Total HEX Deposited For Upcoming Stake: {{ eightFormat(totalAsicDepositForTheCurrentSession) }} $HEX
          </h3>
          <h3 class="my-5">
            Total HEX Deposited for {{(state===0)? "Previous":"This"}} Stake: {{Number(eightFormat(totalAsicDepositForThePreviousSession)) }} $HEX
          </h3>



<!--          <h3 class="my-5" v-if="state===0 && Number(asicDeposits.sessionId) === currentSessionId"> &lt;!&ndash; reload period and they deposited already&ndash;&gt;-->
<!--            Upcoming Stake Ownership: {{ Number(totalAsicDepositForTheCurrentSession) ? (Number(asicDeposits.amount) / Number(totalAsicDepositForTheCurrentSession)).toFixed(2) * 100 : 0 }}%-->
<!--          </h3>-->
<!--          <h3 class="my-5" v-if="state===0 && Number(asicDeposits.sessionId) === currentSessionId-1"> &lt;!&ndash; reload period and they DIDNT deposit YET&ndash;&gt;-->
<!--            Previous Stake Ownership: {{ Number(totalAsicDepositForThePreviousSession) ?( Number(asicDeposits.amount) / Number(totalAsicDepositForThePreviousSession)).toFixed(2) * 100 : 0 }}%-->
<!--          </h3>-->
<!--          <h3 class="my-5" v-if="state===1 && Number(asicDeposits.sessionId) === currentSessionId - 1"> &lt;!&ndash; mining period and they DID deposit&ndash;&gt;-->
<!--            Stake Ownership: {{ Number(totalAsicDepositForThePreviousSession) ?( Number(asicDeposits.amount) / Number(totalAsicDepositForThePreviousSession)).toFixed(2) * 100 : 0 }}%-->
<!--          </h3>-->




          <h3 class="my-5">
            Total HEX {{(state===0)?"Held By":"Staked with"}} the Community Hex Staker: {{ (state===0)? Number(eightFormat(totalAsicHeldByTheAsicMinerAddress)): Number(eightFormat(asicUsedToMine))   }} $HEX
          </h3>
          <h3 class="my-5">
            Number of Deposits in {{(state===0)? "Upcoming Stake":"This Stake" }}: {{ numParticipantsForThisSession }} Deposits
          </h3>
          <h3 class="my-5">
            Number of Deposits in ALL Stakes: {{ numTotalDepositsForAllSessions }} Deposits
          </h3>
          <h3 class="my-5">
            Total HEX Rewards Minted in Previous Stake + Unclaimed: {{ eightFormat(totalPLSBRewards) }} $HEX
          </h3>
          <h3 class="my-5" v-if="state===0">
            Total Unclaimed Rewards: {{ eightFormat(unclaimedRewards) }}
          </h3>
          <h3 class="my-5">
            ETH Fees collected for the Stake Ender: {{ eighteenFormat(hexStakerEthBalance, 3) }}
          </h3>



        </v-card>
      </div>
    </v-row>



    <v-row>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto" >
          <v-btn
              @click="onStartReloadPeriod"
              block
              x-large
              color="yellow"
              class="mt-5 py-5"
              :disabled="!getUserAddress || isLoading || !showStartReloadPeriodButton"

          >
            End Stake
          </v-btn>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto">
          <v-btn
              @click="onStartMiningSession"
              block
              x-large
              color="yellow"
              class="mt-5 py-5"

              :disabled="!getUserAddress || isLoading || !showStartStakedPeriodButton"


          >
            Stake HEX with {{ eightFormat(totalAsicHeldByTheAsicMinerAddress) }} $HEX Tokens
          </v-btn>
        </v-card>
      </div>
    </v-row>









    <v-row class="justify-center">
      <div class="col-12 col-md-6">
        <v-card outlined class="pa-5">
          <h1 class="grey--text text--darken-2">
            The Trapped HEX Release Pool
          </h1>
          <h4 class="font-weight-regular">
            ALL the HEX that has been collected over time will be trapped in
            the contract until this pool reaches 369K $CARN, at which point ALL
            the trapped HEX will be released: 30% to PulseDogecoin Stakers and
            40% to the WAATCA POOL and 30% to a surprise pool :) The 369K CARN will all go to the BUY and BURN Contract.
          </h4>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <div class="col-12">
              <label for=""
              >Amount of CARN Collected So Far:
                {{ twelveFormat(trappedAsicReleasePool) }}</label
              >
              <v-text-field
                  v-model="CARNDepositAmount"
                  outlined
                  class="mt-3"
                  label="CARN Amount"
                  type="text"
              >
                <template v-slot:append>
                  <v-btn
                      text
                      color="primary"
                      @click="CARNDepositAmount = twelveFormat(CARNBalance)"
                      small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                  v-if="isCARNApproved"
                  block
                  x-large
                  color="yellow"
                  class="py-5"
                  @click="onCARNDeposit"
                  :disabled="!getUserAddress || isLoading"
              >
                {{
                  Number(CARNDepositAmount) + Number(eightFormat(trappedAsicReleasePool)) >=
                  100000
                      ? "DEPOSIT CARN AND RELEASE ALL TRAPPED HEX"
                      : "Deposit CARN"
                }}
              </v-btn>
              <v-btn
                  v-else
                  block
                  x-large
                  color="yellow"
                  class="py-5"
                  @click="onCARNApprove"
                  :disabled="!getUserAddress || isLoading"
              >
                Approve CARN
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "HEXStaker",
  data() {
    return {
      text: "Reload",
      clearIntervalX: null,


      HEXBalance: 0,
      CARNBalance: 0,

      isASICApproved: false,
      isCARNApproved: false,

      HEXDepositAmount: 0,
      CARNDepositAmount: 0,

      state: 0,
      currentSessionId: 0,
      asicDeposits: 0,
      nextMiningStartTime: 0,
      nextReloadTime: 0,

      unclaimedRewards: 0,
      totalPLSBRewards: 0,
      trappedAsicReleasePool: 0,
      totalAsicDepositForTheCurrentSession: 0,
      totalAsicDepositForThePreviousSession: 0,
      totalAsicHeldByTheAsicMinerAddress: 0,
      numTotalDepositsForAllSessions: 0,
      numParticipantsForThisSession: 0,
      asicUsedToMine: 0,
      hexStakerEthBalance: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    readValues() {
      let address = this.getUserAddress;
      Promise.all([
        this.getHEXInstance.methods
            .allowance(address, this.HEX_STAKER_ADDRESS)
            .call(),
        this.getCARNInstance.methods
            .allowance(address, this.HEX_STAKER_ADDRESS)
            .call(),
        this.getHEXInstance.methods.balanceOf(address).call(),
        this.getCARNInstance.methods.balanceOf(address).call(),
        this.getHEXStakerInstance.methods.state().call(),
        this.getHEXStakerInstance.methods.currentSessionId().call(),
        this.getHEXStakerInstance.methods.hexDeposits(address).call(),
        this.getHEXStakerInstance.methods.nextStakingStartTime().call(),
        this.getHEXStakerInstance.methods.unclaimedRewards().call(),
        this.getHEXStakerInstance.methods.totalRewards().call(),
        this.getHEXStakerInstance.methods.trappedHexReleasePool().call(),
        this.getHEXStakerInstance.methods.totalHexDepositForTheCurrentSession().call(),
        this.getHEXStakerInstance.methods.totalHexDepositForThePreviousSession().call(),

        this.getHEXInstance.methods.balanceOf(this.HEX_STAKER_ADDRESS).call(),
        this.getHEXStakerInstance.methods.balanceBefore().call(),
        this.getHEXStakerInstance.methods.numTotalDepositsForAllSessions().call(),
        this.getHEXStakerInstance.methods.numParticipantsForThisSession().call(),
        this.getWeb3.eth.getBalance(this.HEX_STAKER_ADDRESS)




      ]).then(
          async ([
                   isASICApproved,
                   isCARNApproved,
                   HEXBalance,
                   CARNBalance,
                   state,
                   currentSessionId,
                   asicDeposits,
                   nextMiningStartTime,
                   unclaimedRewards,
                   totalPLSBRewards,
                   trappedAsicReleasePool,
                   totalAsicDepositForTheCurrentSession,
                   totalAsicDepositForThePreviousSession,
                   totalAsicHeldByTheAsicMinerAddress,
                   asicUsedToMine,
                   numTotalDepositsForAllSessions,
                   numParticipantsForThisSession,
                   hexStakerEthBalance
                 ]) => {
            console.log("isASICApproved:", !!Number(isASICApproved));
            console.log("isCARNApproved:", !!Number(isCARNApproved));
            console.log("HEXBalance:", this.eightFormat(HEXBalance));
            console.log("CARNBalance:", this.twelveFormat(CARNBalance));
            console.log("state:", state);
            console.log("currentSessionId:", currentSessionId);
            console.log("asicDeposits:", asicDeposits);
            console.log("nextMiningStartTime:", nextMiningStartTime);
            console.log("unclaimedRewards:", unclaimedRewards);
            console.log("totalPLSBRewards:", totalPLSBRewards);
            console.log("trappedAsicReleasePool:", trappedAsicReleasePool);
            console.log("totalAsicDepositForTheCurrentSession:", totalAsicDepositForTheCurrentSession);
            console.log("totalAsicDepositForThePreviousSession:", totalAsicDepositForThePreviousSession);
            console.log("totalAsicHeldByTheAsicMinerAddress: ", totalAsicHeldByTheAsicMinerAddress)
            console.log("asicUsedToMine:", asicUsedToMine);
            console.log("numTotalDepositsForAllSessions:", numTotalDepositsForAllSessions);
            console.log("numParticipantsForThisSession: ", numParticipantsForThisSession)
            console.log("ASIC Approved amount:", Number(isASICApproved));
            console.log("CARN Approved amount:", Number(isCARNApproved));
            console.log("hexStakerEthBalance:", Number(hexStakerEthBalance));
            this.isASICApproved = Number(isASICApproved) > 0 ? true : false;
            this.isCARNApproved = Number(isCARNApproved) > 0 ? true : false;
            this.HEXBalance = HEXBalance;
            this.CARNBalance = CARNBalance;
            this.state = Number(state);
            this.currentSessionId = Number(currentSessionId);
            this.asicDeposits = asicDeposits;
            this.nextMiningStartTime = Number(nextMiningStartTime);
            this.unclaimedRewards = unclaimedRewards;
            this.totalPLSBRewards = totalPLSBRewards;
            this.trappedAsicReleasePool = trappedAsicReleasePool;
            this.totalAsicDepositForTheCurrentSession = totalAsicDepositForTheCurrentSession;
            this.totalAsicDepositForThePreviousSession = totalAsicDepositForThePreviousSession;
            this.totalAsicHeldByTheAsicMinerAddress = totalAsicHeldByTheAsicMinerAddress;
            this.hexStakerEthBalance = hexStakerEthBalance;
            this.asicUsedToMine = asicUsedToMine;
            this.numTotalDepositsForAllSessions = numTotalDepositsForAllSessions;
            this.numParticipantsForThisSession = numParticipantsForThisSession;
            // this.totalStaked = await this.getASICMinerInstance.methods
            //   .stakePool(this.currentSessionId - 1)
            //   .call();
            // console.log("totalStaked:", this.totalStaked);
            if (!this.state) {
              console.log("********************state = 0***********************")

              clearInterval(this.clearIntervalX);
              this.counter();
            }


            if (this.state) {
              console.log("********************state = 1***********************")
              Promise.all(
                  [
                      this.getHEXStakerInstance.methods.getStakeStore().call(),
                      this.getHEXInstance.methods.currentDay().call(),]
              ).then(
                  async ([getStakeStore, hexCurrentDay]) => {
                    //hexCurrentDay = 1422;
                    console.log("getStakeStore******************: ", getStakeStore)
                    console.log("hexCurrentDay", hexCurrentDay)
                    console.log("getStakeStore[3]", getStakeStore[3])
                    this.nextReloadTime = 2-( (Number(hexCurrentDay)) - Number(getStakeStore[3]) )
                    console.log("this.nextReloadTime!! ", this.nextReloadTime)



                    clearInterval(this.clearIntervalX);
                    this.counter();
                  }
              );
            }


          }
      );



    },

    counter() {
      let countDownDate = 0;

      if (this.state === 0) {
        this.text = "Reload Period";
        countDownDate = this.nextMiningStartTime * 1000;
        console.log("here state is 0");
      } else if (this.state === 1) {
        this.text = "Hex Stake";
        countDownDate = this.nextReloadTime;
        console.log("here state is 1!!!");
      }
      console.log("countDownDate: ", countDownDate)
      let state = this.state

      this.clearIntervalX = setInterval(function () {
        console.log('inside the clearintervalX the state is: ', state)
        console.log('inside the clearintervalX the state is: ',  !!state)

        if (!state) {
          console.log('inside state = 0')

          let now = (new Date().getTime() + (0) * 86400000)
          const distance = countDownDate - now;
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if (distance <= 0) {
            clearInterval(this.clearIntervalX);
            document.getElementById(
                "demo"
            ).innerHTML = `0 Days 0 Hours 0 Minutes 0 Seconds`;
          } else {
            document.getElementById(
                "demo"
            ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
          }

        } else {
          console.log('inside state = 1')

          if (countDownDate <= 0) {
            clearInterval(this.clearIntervalX);
            document.getElementById(
                "demo"
            ).innerHTML = `0 Days`;
          } else {
            document.getElementById(
                "demo"
            ).innerHTML = `${countDownDate} Days `;
          }
        }


      }, 1000);
    },

    onClaimReward() {
      this.SET_LOADING(true);
      this.getHEXStakerInstance.methods
          .claimReward()
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Claim Rewards transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("Claim Rewards transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Claim Rewards transaction has Failed");
          });
    },

    // ASIC
    onASICApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }

      if (this.isASICApproved) {
        this.onASICDeposit();
        return;
      }

      this.SET_LOADING(true);
      this.getHEXInstance.methods
          .approve(this.HEX_STAKER_ADDRESS, "1000000000000000000000000000")
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("HEX Approve transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("HEX Approve transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("HEX Approve transaction has Failed");
          });
    },

    onASICDeposit() {
      if (!this.HEXDepositAmount) {
        this.$toasted.show("Enter HEX Staking Amount");
        return;
      }
      if (Number(this.HEXDepositAmount) < 10) {
        this.$toasted.show(
            "You need at least 100 HEX in your account to deposit"
        );
        return;
      }
      if (
          Number(this.HEXDepositAmount) >
          Number(this.eightFormat(this.HEXBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      if (Number(this.twelveFormat(this.CARNBalance)) < 10) {
        this.$toasted.show(
            "You need at least 10 CARN in your account to deposit"
        );
        return;
      }
      this.SET_LOADING(true);
      console.log("DEPOSIT:", this.eightToWei(this.HEXDepositAmount))
      this.getHEXStakerInstance.methods
          .deposit(this.eightToWei(this.HEXDepositAmount))
          .send({
            from: this.getUserAddress,
            value: this.getWeb3.utils.toWei("0.002", "ether")
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("HEX Deposit transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.clearForm();
            this.SET_LOADING(false);
            this.$toasted.show("HEX Deposit transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("HEX Deposit transaction has Failed");
          });
    },

    onStartMiningSession() {
      this.SET_LOADING(true);
      this.getHEXStakerInstance.methods
          .startStakingSession()
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Start Staking Session transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("Start Staking Session transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Start Staking Session transaction has Failed");
          });
    },

    onStartReloadPeriod() {
      this.SET_LOADING(true);
      this.getHEXStakerInstance.methods
          .startReloadPeriod()
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Start Reload Period transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("Start Reload Period transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Start Reload Period transaction has Failed");
          });
    },

    // CARN
    onCARNApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }

      this.SET_LOADING(true);
      this.getCARNInstance.methods
          .approve(this.HEX_STAKER_ADDRESS, "1000000000000000000000000000")
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("CARN Approve transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("CARN Approve transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("CARN Approve transaction has Failed");
          });
    },

    onCARNDeposit() {
      if (!this.CARNDepositAmount) {
        this.$toasted.show("Enter CARN Deposit Amount");
        return;
      }

      if (
          Number(this.CARNDepositAmount) >
          Number(this.twelveFormat(this.CARNBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }

      this.SET_LOADING(true);
      this.getHEXStakerInstance.methods
          .depositCARNToTrappedPool(this.twelveToWei(this.CARNDepositAmount))
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("CARN Deposit transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.clearForm();
            this.SET_LOADING(false);
            this.$toasted.show("CARN Deposit transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("CARN Deposit transaction has Failed");
          });
    },

    clearForm() {
      this.HEXDepositAmount = 0;
      this.CARNDepositAmount = 0;
    },
  },
  computed: {
    showStartReloadPeriodButton() {
      //1676252220
      return (
          this.state === 1 && this.nextReloadTime <= 0
      );
    },
    showStartStakedPeriodButton() {
      return (
          this.state === 0 && (new Date().getTime() + (0)*86400000) > this.nextMiningStartTime * 1000
      );
    },
  },
  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
<style>
.v-main.Hex .v-main__wrap {
  background-image: url("../assets/hexlogos2.jpg");
  background-size: 150%;
  background-position: center;

}
.disable-events {
  pointer-events: none
}
</style>
