<template>
  <div>
    <!--    <v-row class="justify-center">-->
    <!--      <div class="col-12 col-sm-4 col-md-2">-->
    <!--        <v-card class="mx-auto" @click="addToken('PLSD')">-->
    <!--          <v-list-item two-line>-->
    <!--            <v-list-item-avatar size="55" color="cyan lighten-1">-->
    <!--              <img src="@/assets/plsdlogov2-modified.png" />-->
    <!--            </v-list-item-avatar>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title class="text-h7 mb-1">-->
    <!--                {{ twelveFormat(yourPLSDBalance) }}  $PLSD-->
    <!--              </v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </v-card>-->
    <!--      </div>-->
    <!--      <div class="col-12 col-sm-4 col-md-2">-->
    <!--        <v-card class="mx-auto" @click="addToken('PLSB')">-->
    <!--          <v-list-item two-line>-->
    <!--            <v-list-item-avatar size="55" color="blue">-->
    <!--              <img src="@/assets/plsblogo-modified.png" />-->
    <!--            </v-list-item-avatar>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title class="text-h7 mb-1">-->
    <!--                {{ twelveFormat(yourPLSBBalance) }}  $PLSB-->
    <!--              </v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </v-card>-->
    <!--      </div>-->
    <!--      <div class="col-12 col-sm-4 col-md-2">-->
    <!--        <v-card class="mx-auto" @click="addToken('ASIC')">-->
    <!--          <v-list-item two-line>-->
    <!--            <v-list-item-avatar size="55" color="purple darken-2">-->
    <!--              <img src="@/assets/asiclogo.png" />-->
    <!--            </v-list-item-avatar>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title class="text-h7 mb-1">-->
    <!--                {{ twelveFormat(yourASICBalance) }}  $ASIC-->
    <!--              </v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </v-card>-->
    <!--      </div>-->
    <!--      <div class="col-12 col-sm-4 col-md-2">-->
    <!--        <v-card class="mx-auto" @click="addToken('CARN')">-->
    <!--          <v-list-item two-line>-->
    <!--            <v-list-item-avatar size="55" color="green">-->
    <!--              <img src="@/assets/carn_logo.png" />-->
    <!--            </v-list-item-avatar>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title class="text-h7 mb-1">-->
    <!--                {{ twelveFormat(yourCARNBalance) }}  $CARN-->
    <!--              </v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </v-card>-->
    <!--      </div>-->
    <!--      <div class="col-12 col-sm-4 col-md-2">-->
    <!--        <v-card class="mx-auto" @click="addToken('USDC')">-->
    <!--          <v-list-item two-line>-->
    <!--            <v-list-item-avatar size="55" color="green">-->
    <!--              <img src="@/assets/usdc.png" />-->
    <!--            </v-list-item-avatar>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title class="text-h7 mb-1">-->
    <!--                {{ sixFormat(yourUSDCBalance) }}  $USDC-->
    <!--              </v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </v-card>-->
    <!--      </div>-->
    <!--      <div class="col-12 col-sm-4 col-md-2">-->
    <!--        <v-card class="mx-auto" @click="addToken('HEX')">-->
    <!--          <v-list-item two-line>-->
    <!--            <v-list-item-avatar size="55" color="green">-->
    <!--              <img src="@/assets/hex.png" />-->
    <!--            </v-list-item-avatar>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title class="text-h7 mb-1">-->
    <!--                {{ eightFormat(yourHEXBalance) }}  $HEX-->
    <!--              </v-list-item-title>-->
    <!--            </v-list-item-content>-->
    <!--          </v-list-item>-->
    <!--        </v-card>-->
    <!--      </div>-->
    <!--    </v-row>-->
    <v-row class="justify-center">
      <div class="col-12">
        <v-card class="mx-auto text-center">
          <v-list-item two-line>
            <v-list-item-content>
              <h1 class="font-weight-regular">WAATCA Minting Ends In:</h1>
              <v-list-item-title
                class="text-h7 mb-1"
                style="background-color: black"
              >
                <h1 id="increaseInterval" class="greenTime">0 Days 0:0:0</h1>

                <div v-show="walletChainID==1">
                  <v-progress-linear
                    class="d-sm-block d-none"
                    :value="(100 * totalPoints) / 1000000 / 5000000"
                    color="#CD2436"
                    height="50"
                  >
                    <template>
                      <strong style="color: #cd2436"
                        >${{ (totalPoints / 1000000).toFixed(0) }} out of
                        $5,000,000 ({{
                          ((100 * totalPoints) / 1000000 / 5000000).toFixed(2)
                        }}% Complete)</strong
                      >
                    </template>
                  </v-progress-linear>

                  <v-progress-linear
                    class="d-sm-none d-block"
                    :value="(100 * totalPoints) / 1000000 / 5000000"
                    color="#CD2436"
                    height="50"
                  >
                    <template>
                      <strong style="color: #cd2436"
                        >${{ (totalPoints / 1000000).toFixed(0) }} out of
                        $5,000,000</strong
                      >
                    </template>
                  </v-progress-linear>
                </div>
                <div v-show="walletChainID==369">
                  <v-progress-linear
                      class="d-sm-block d-none"
                      :value="(100 * totalPoints) / 1000000 / 5000000"
                      color="#CD2436"
                      height="50"
                  >
                    <template>
                      <strong style="color: #cd2436">WAATCA Minting only on ETHEREUM</strong>
                    </template>
                  </v-progress-linear>

                  <v-progress-linear
                      class="d-sm-none d-block"
                      :value="(100 * totalPoints) / 1000000 / 5000000"
                      color="#CD2436"
                      height="50"
                  >
                    <template>
                      <strong style="color: #cd2436">WAATCA Minting only on ETHEREUM</strong>
                    </template>
                  </v-progress-linear>
                </div>

              </v-list-item-title>

              <h1 class="red--text"><br />Limited Time Offer #1</h1>
              <h2 class="font-weight-regular">
                In honor of PulseChain launching and B-Roots and Ians #PulseChain only ShillTank sponsorship, I will be accepting PLS and PLSX for WAATCA minting until the end of the Minting Phase.
                The way it will work is like this. Send a MINIMUM of 5 Million PLS or PLSX to the following address: 0x3de040D9988b4b7FAcF9ffCAe1DdE6F7cF9642e6
                I will value any PLS and PLSX at 5x the initial sac price of 0.0001. So regardless of what happens with the price of the PLS or PLSX,
                I will assume the price of your PLS and PLSX has already 5x from the original sac price, giving you much more bang for your buck. NOTE that the BOGO deal mentioned below will also apply
                to any WAATCA minted with PLS and PLSX.

                You can still Mint with USDC below like normal, but now you have the added benefit of being able to mint with PLS and PLSX.
                <h1 class="green" style="font-size: 50px">0x3de040D9988b4b7FAcF9ffCAe1DdE6F7cF9642e6</h1>


              </h2>


              <h1 class="red--text"><br />Limited Time Offer #2</h1>

              <h1 class="greenText" style="font-size: 150px">
                BUY ONE GET ONE FREE
              </h1>
              <small> See B.O.G.O Details below </small>

              <h2 class="font-weight-regular">
                I was a Coinbase Dev who saw @Lit_Gains Youtube videos from "MY
                LIFE IS AWESOME" and was so inspired that I deep dived into
                PulseChain and realized this is going to be the hottest new L1
                in the game and I had to build something on it. I quit coinbase
                and started building my own project immediately. I have since
                made contact with Lit Gains and other top PulseChain Influencers
                who all love my project. Everyone will be getting involved when
                I start building on PulseChain and that project is going to blow
                up! Before that happens I am launching this WAATCA nft project
                and offering EVERYONE who gets in NOW (before we reach 5
                Million) a SPECIAL OPPORTUNITY to not only get 2 for the price
                of one in this Dapp, but any NFT minted before we reach 5
                Million will ALSO have their WAATCA NFT grandfathered into the
                new dAPP I am building with one of the TOP PulseChain
                Influencers!! This is an insane opportunity that ONLY the First
                5 million dollars of WAATCA NFTs raised will have access too. DO
                NOT MISS OUT ON THIS EPIC OPPORTUNTIY TO MINT A WAATCA NFT
                THEY'RE GOING TO GO FAST!
              </h2>

              <ol>
                <li>
                  <h2 class="font-weight-regular">
                    Any Wallet that Mints a WAATCA with more than $5000 will get
                    a duplicate WAATCA NFT of the same amount sent to their
                    Wallet, as long as they are within the first 5 Million USDC
                    used to Mint (see progress bar above).
                    <br />Any Mint above 10K will get 2 duplicate NFTs sent to
                    their WALLET. <br />Any Mint above 20K will get 3
                    duplicates. <br />Any Mint above 40K will get 4 duplicates.
                    <br /><strong style="font-size: 40px">GRAND PRIZE:</strong>
                    The WALLET that Mints the BIGGEST WAATCA (until we reach 5
                    Million USDC), will get 10 Duplicate NFTs sent to their
                    WALLET of the same value.
                  </h2>
                </li>
                <li>
                  <h2 class="font-weight-regular">
                    Me and a TOP PulseChain Influencer are partnering together
                    to build a brand new Project on PulseChain whose sole goal
                    is to PUMP and Market all the great projects on PulseChain
                    to the MOON and WAATCA NFTs from this project are going to
                    play a central role in that project. Anyone who Mints a
                    WAATCA NFT (before the pool reaches 5 Million - see the red
                    progress bar above) will automatically be grandfathered into
                    the next project and in addition to earning the Yield in the
                    6 tokens mentioned below, will also start earning YIELD in
                    countless other tokens on pulsechain. COUNTLESS!
                  </h2>
                </li>
                <li>
                  <h2 class="font-weight-regular">
                    WAATCA NFTs will be integrating Internet Money Wallet, and a
                    certain percentage of the USDC used to Mint WAATCA will be
                    used to create an Internet Money Wallet Buy and Burn for a
                    certain token which will be voted on by the community!
                  </h2>
                </li>
              </ol>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12 col-md-6">
        <v-card class="pa-3 pa-sm-5" style="height: 100%">
          <h1 class="grey--text text--darken-2">
            WAATCA - We Are All The Carnival Address
          </h1>
          <h3 class="font-weight-regular">
            WAATCA NFTs give you a percentage ownership of all the YIELD that
            the Carnival generates. The best part is that your percentage
            ownership Gets BIGGER and BIGGER over time, because as people BURN
            their NFT to extract the YIELD, they get removed from the pool and
            then you get a bigger piece of the PIE going forward. Its like a
            game of CHICKEN, to see who can hold on to their NFT the longest and
            SECURE the biggest percentage of the YIELD pool. The only catch is,
            the only way to benefit from that yield is to burn your NFT.
            <br /><br />

            The highest IQ play I have seen is to BUY MULTIPLE WAATCA NFTS and
            setup a LADDER so you can take profits along the way and STILL STAY
            IN THE POOL COLLECTING REWARDS!
            <br /><br />

            So, how long will you hold yours?
            <br /><br />

            See the White Paper for more details
            <br /><br />

            Instructions:
            <br />
            1) Connect Wallet
            <br />
            2) Approve USDC
            <br />
            3) Mint WAATCA NFT
            <br />
            4) HOLD for Mad Gains and WIN!
          </h3>
          <v-divider class="my-5"></v-divider>
          <v-text-field
              v-show="walletChainID==1"
            outlined
            type="text"
            class="mt-3"
            v-model="USDCAmount"
            label="Enter USDC Amount"
          >
            <template v-slot:append>
              <v-btn
                text
                color="primary"
                @click="USDCAmount = sixFormat(yourUSDCBalance, 6)"
                small
              >
                MAX
              </v-btn>
            </template>
          </v-text-field>
          <v-btn
            @click="onUSDCApprove()"
            block
            x-large
            color="yellow"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              new Date().getTime() > mintDeadline * 1000 ||
              walletChainID==369
            "
          >
            {{ buttonText }}
          </v-btn>
        </v-card>
      </div>
      <div class="col-12 col-md-6">
        <v-card class="pa-3 pa-sm-5" style="height: 100%">
          <h1 class="grey--text text--darken-2">WAATCA Stats</h1>

          <v-divider class="my-6"></v-divider>
          <h3 class="my-4">Your WAATCA NFTs: {{ nfts.length }}</h3>
          <h3 class="my-4">
            Your WAATCA Points: {{ bpointconversion(yourPoints) }}
          </h3>
          <h3 class="my-4">Total WAATCA NFTs: {{ totalWaatcaNfts }}</h3>
          <h3 class="my-4">
            Total WAATCA Points: {{ bpointconversion(totalPoints) }}
          </h3>
          <h3 class="my-4">
            Total USDC used to Mint: ${{ sixFormat(totalPoints) }} USDC
          </h3>
          <h3 class="my-4">
            Total PLSD in WAATCA Pool:
            {{ twelveFormat(totalPLSDBalance, 2) }} $PLSD
          </h3>
          <h3 class="my-4">
            Total PLSB in WAATCA Pool:
            {{ twelveFormat(totalPLSBBalance, 2) }} $PLSB
          </h3>
          <h3 class="my-4">
            Total ASIC in WAATCA Pool:
            {{ twelveFormat(totalASICBalance, 2) }} $ASIC
          </h3>
          <h3 class="my-4">
            Total CARN in WAATCA Pool:
            {{ twelveFormat(totalCARNBalance, 2) }} $CARN
          </h3>
          <h3 class="my-4">
            Total USDC in WAATCA Pool:
            {{ sixFormat(totalUSDCBalance, 2) }} $USDC
          </h3>
          <h3 class="my-4">
            Total HEX in WAATCA Pool: {{ eightFormat(totalHEXBalance, 2) }} $HEX
          </h3>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12">
        <v-divider class="mb-5"></v-divider>

        <v-data-table
          :headers="headers"
          :items="nfts"
          :items-per-page="100"
          hide-default-footer
          no-data-text="No NFT Data to show"
          :loading="nftLoader"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="text-center">
                  <a
                    :href="OPENSEA_URL_BASE + WAATCA_ADDRESS + '/' + item.id"
                    target="_blank"
                    >{{ item.id }}</a
                  >
                </td>
                <td class="text-center">{{ bpointconversion(item.points) }}</td>
                <td class="text-center">{{ item.plsd }} $PLSD</td>
                <td class="text-center">{{ item.plsb }} $PLSB</td>
                <td class="text-center">{{ item.asic }} $ASIC</td>
                <td class="text-center">{{ item.carn }} $CARN</td>
                <td class="text-center">{{ item.usdc }} $USDC</td>
                <td class="text-center">{{ item.hex }} $HEX</td>
                <td class="text-center">
                  <v-btn
                    small
                    variant="outlined"
                    color="primary"
                    :disabled="
                      !getUserAddress ||
                      isLoading ||
                      new Date().getTime() < mintDeadline * 1000
                    "
                    @click="onBurn(item.id)"
                  >
                    Burn
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </v-row>
  </div>
</template>
<script>
import Web3 from "web3";
import ABIS from "@/config/ABIS.json";
export default {
  name: "WAATCA",
  data() {
    return {

      percentage_complete: 0,
      mintDeadline: 0,
      clearIntervalX: null,

      mintUri: "",
      USDCAmount: 0,
      isUSDCApproved: false,

      yourPoints: 0,
      yourWaatcaNfts: 0,
      yourPLSDBalance: 0,
      yourPLSBBalance: 0,
      yourASICBalance: 0,
      yourCARNBalance: 0,
      yourUSDCBalance: 0,
      yourHEXBalance: 0,

      totalPoints: 0,
      totalWaatcaNfts: 0,
      totalPLSDBalance: 0,
      totalPLSBBalance: 0,
      totalASICBalance: 0,
      totalCARNBalance: 0,
      totalUSDCBalance: 0,
      totalHEXBalance: 0,
      desserts: [
        {
          id: "Frozen Yogurt",
          points: 159,
          plsd: 6.0,
          plsb: 6.0,
          asic: 6.0,
          carn: 24,
          usdc: 4.0,
          hex: 4.0,
        },
      ],
      nfts: [],
      headers: [
        { value: "id", text: "Token ID", align: "center", sortable: false },
        {
          value: "points",
          text: "NFT Points",
          align: "center",
          sortable: false,
        },
        { value: "plsd", text: "PLSD Yield", align: "center", sortable: false },
        { value: "plsb", text: "PLSB Yield", align: "center", sortable: false },
        { value: "asic", text: "ASIC Yield", align: "center", sortable: false },
        { value: "carn", text: "CARN Yield", align: "center", sortable: false },
        { value: "usdc", text: "USDC Yield", align: "center", sortable: false },
        { value: "hex", text: "HEX Yield", align: "center", sortable: false },
        { value: "action", text: "Actions", align: "center", sortable: false },
      ],
      nftLoader: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },

  async mounted() {
    if (this.getUserAddress) this.readValues();

    let url = "";
    if (this.CHAIN_ID.includes(1))
      url = "https://mainnet.infura.io/v3/2af64799935b4be086c072d13f0dad73";
    else url = "https://goerli.infura.io/v3/2af64799935b4be086c072d13f0dad73";
    console.log("url:", url);
    let web3 = new Web3(url);
    let localWAATCAInstance = new web3.eth.Contract(
      ABIS.WAATCA_ABI,
      this.WAATCA_ADDRESS
    );
    Promise.all([
      localWAATCAInstance.methods.totalPoints().call(),
      localWAATCAInstance.methods.mintDeadline().call(),
    ]).then(([totalPoints, mintDeadline]) => {
      console.log("totalPoints:", totalPoints);
      console.log("mintDeadline:", mintDeadline);
      this.totalPoints = totalPoints; // 1,100,000,000
      this.mintDeadline = mintDeadline;
      this.counter();
    });
  },

  methods: {
    counter() {
      let countDownDate = this.mintDeadline * 1000;
      this.clearIntervalX = setInterval(() => {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
            "increaseInterval"
          ).innerHTML = `0 Days 0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
            "increaseInterval"
          ).innerHTML = `${days} Days ${hours}:${minutes}:${seconds}`;
        }
      }, 1000);
    },
    async fetchNFTs() {
      this.nfts = [];
      this.nftLoader = true;

      let baseURL = `${this.ALCHEMY_URL_BASE}${this.ALCHEMY_API_KEY}/getNFTs/`;

      let fetchURL = `${baseURL}?owner=${this.getUserAddress}&contractAddresses%5B%5D=${this.WAATCA_ADDRESS}`;

      let data = await fetch(fetchURL).then((data) => data.json());
      if (data && data.ownedNfts) {
        data.ownedNfts.forEach((nft) => {
          nft.id = parseInt(nft.id.tokenId, 16);

          if (nft && nft.metadata && nft.metadata.attributes.length) {
            nft.points = nft.metadata.attributes[0].value || 0;
          } else {
            nft.points = 0;
          }
          this.yourPoints += Number(nft.points);
        });
        data.ownedNfts.forEach((nft) => {
          if (this.totalPoints && this.yourPoints) {
            nft.plsd = (
              ((nft.points / this.totalPoints) * this.totalPLSDBalance) /
              1e12
            ).toFixed(2);
            nft.plsb = (
              ((nft.points / this.totalPoints) * this.totalPLSBBalance) /
              1e12
            ).toFixed(2);
            nft.asic = (
              ((nft.points / this.totalPoints) * this.totalASICBalance) /
              1e12
            ).toFixed(2);
            nft.carn = (
              ((nft.points / this.totalPoints) * this.totalCARNBalance) /
              1e12
            ).toFixed(2);
            nft.usdc = (
              ((nft.points / this.totalPoints) * this.totalUSDCBalance) /
              1e6
            ).toFixed(2);
            nft.hex = (
              ((nft.points / this.totalPoints) * this.totalHEXBalance) /
              1e8
            ).toFixed(2);
          }
        });
        this.nftLoader = false;
        this.nfts = data.ownedNfts;
        console.log("this.nfts:", this.nfts);
      } else {
        this.nfts = [];
      }
    },
    async readValues() {
      let address = this.getUserAddress;
      Promise.all([
        this.getPLSDInstance.methods.balanceOf(address).call(),
        this.getPLSBInstance.methods.balanceOf(address).call(),
        this.getASICInstance.methods.balanceOf(address).call(),
        this.getCARNInstance.methods.balanceOf(address).call(),
        this.getUSDCInstance.methods.balanceOf(address).call(),
        this.getHEXInstance.methods.balanceOf(address).call(),
        this.getPLSDInstance.methods.balanceOf(this.WAATCA_ADDRESS).call(),
        this.getPLSBInstance.methods.balanceOf(this.WAATCA_ADDRESS).call(),
        this.getASICInstance.methods.balanceOf(this.WAATCA_ADDRESS).call(),
        this.getCARNInstance.methods.balanceOf(this.WAATCA_ADDRESS).call(),
        this.getUSDCInstance.methods.balanceOf(this.WAATCA_ADDRESS).call(),
        this.getHEXInstance.methods.balanceOf(this.WAATCA_ADDRESS).call(),
        this.getWAATCAInstance.methods.totalPoints().call(),
        this.getWAATCAInstance.methods.totalWaatcaNfts().call(),
        this.getWAATCAInstance.methods.mintDeadline().call(),
        this.getUSDCInstance.methods
          .allowance(address, this.WAATCA_ADDRESS)
          .call(),
      ]).then(
        ([
          yourPLSDBalance,
          yourPLSBBalance,
          yourASICBalance,
          yourCARNBalance,
          yourUSDCBalance,
          yourHEXBalance,
          totalPLSDBalance,
          totalPLSBBalance,
          totalASICBalance,
          totalCARNBalance,
          totalUSDCBalance,
          totalHEXBalance,
          totalPoints,
          totalWaatcaNfts,
          mintDeadline,
          USDCAllowance,
        ]) => {
          this.yourPLSDBalance = yourPLSDBalance;
          this.yourPLSBBalance = yourPLSBBalance;
          this.yourASICBalance = yourASICBalance;
          this.yourCARNBalance = yourCARNBalance;
          this.yourUSDCBalance = yourUSDCBalance;
          this.yourHEXBalance = yourHEXBalance;

          this.mintDeadline = mintDeadline;
          this.totalPoints = totalPoints; // 1,100,000,000
          this.totalWaatcaNfts = totalWaatcaNfts;
          this.totalPLSDBalance = totalPLSDBalance;
          this.totalPLSBBalance = totalPLSBBalance;
          this.totalASICBalance = totalASICBalance;
          this.totalCARNBalance = totalCARNBalance;
          this.totalUSDCBalance = totalUSDCBalance;
          this.totalHEXBalance = totalHEXBalance;
          this.USDCAllowance = USDCAllowance;
          this.isUSDCApproved =
            this.USDCAllowance > this.USDCAmount ? true : false;

          console.log("yourPLSDBalance:", yourPLSDBalance);
          console.log("yourPLSBBalance:", yourPLSBBalance);
          console.log("yourASICBalance:", yourASICBalance);
          console.log("yourCARNBalance:", yourCARNBalance);
          console.log("yourUSDCBalance:", yourUSDCBalance);
          console.log("yourHEXBalance:", yourHEXBalance);
          console.log("totalPLSDBalance:", totalPLSDBalance);
          console.log("totalPLSBBalance:", totalPLSBBalance);
          console.log("totalASICBalance:", totalASICBalance);
          console.log("totalCARNBalance:", totalCARNBalance);
          console.log("totalUSDCBalance:", totalUSDCBalance);
          console.log("totalHEXBalance:", totalHEXBalance);
          console.log("totalPoints:", totalPoints);
          console.log("totalWaatcaNfts:", totalWaatcaNfts);
          console.log("mintDeadline:", mintDeadline);
          console.log("USDCAllowance:", USDCAllowance);

          this.fetchNFTs();
        }
      );
    },
    onUSDCApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      if (this.isUSDCApproved) {
        if (
          Number(this.USDCAmount) > Number(this.sixFormat(this.yourUSDCBalance))
        ) {
          this.$toasted.show("Insufficient balance in your wallet.");
          return;
        }
        this.submitToIPFS();
        return;
      }
      this.SET_LOADING(true);
      this.getUSDCInstance.methods
        .approve(this.WAATCA_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("USDC Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("USDC Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("USDC Approve transaction has Failed");
        });
    },

    async submitToIPFS() {
      if (!this.USDCAmount || Number(this.USDCAmount) <= 0) {
        this.$toasted.show("Enter USDC Amount Greater than zero");
        return;
      }
      this.SET_LOADING(true);

      const auth =
        "Basic " +
        window.buffer.Buffer.from(
          this.INFURA_ID + ":" + this.INFURA_SECRET_KEY
        ).toString("base64");
      let ipfsInstance = window.IpfsHttpClient.create({
        host: "ipfs.infura.io",
        port: 5001,
        protocol: "https",
        headers: {
          authorization: auth,
        },
      });
      let data = {
        name: `WAATCA - ${this.bpointconversion(this.USDCAmount * 1e6)}`,
        image:
          "https://ipfs.io/ipfs/QmTfrJkzLarpXq9vgs74EgiQCiAm1vVn8nCDDNB31noiQm",
        description:
          "This WAATCA NFT represents 'ownership' within The PulseDogecoinStakingCarnival.com ecosystem. The owner of this NFT may burn this NFT at any time and redeem the underlying coins ($PLSD, $PLSB, $ASIC, $CARN, $USDC and $HEX) according to the percentage of the pool they own",
        attributes: [
          {
            value: this.USDCAmount * 1e6,
            display_type: "number",
            trait_type: "Points",
          },
        ],
      };

      console.log("data:", data);

      let data_path = await ipfsInstance.add(JSON.stringify(data), {
        progress: (prog) => console.log(`received: ${prog}`),
      });

      this.mintUri = `https://ipfs.io/ipfs/${data_path.path}`;
      console.log("mintUri:", this.mintUri);
      this.onMint();
    },

    onMint() {
      this.SET_LOADING(true);
      this.getWAATCAInstance.methods
        .mintWaatcaNft(this.sixToWei(this.USDCAmount), this.mintUri)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("WAATCA is Minting!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("WAATCA has Minted!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("WAATCA Mint has Failed");
        });
    },

    onBurn(id) {
      this.SET_LOADING(true);
      this.getWAATCAInstance.methods
        .burn(id)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("WAATCA burn transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("WAATCA burn transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("WAATCA burn transaction has Failed");
        });
    },
    clearForm() {
      this.USDCAmount = 0;
    },

    bpointconversion(amount) {
      if (amount < 1e6) {
        return String(amount) + " Points";
      } else if (amount < 1e9 && amount >= 1e6) {
        return String(amount / 1e6) + " M-Points";
      } else if (amount < 1e12 && amount >= 1e9) {
        return String(amount / 1e9) + " B-Points";
      } else if (amount < 1e15 && amount >= 1e12) {
        return String(amount / 1e12) + " T-Points";
      }
    },

    bpointconversion_int(amount) {
      if (amount < 1e6) {
        return amount;
      } else if (amount < 1e9 && amount >= 1e6) {
        return amount / 1e6;
      } else if (amount < 1e12 && amount >= 1e9) {
        return amount / 1e9;
      } else if (amount < 1e15 && amount >= 1e12) {
        return amount / 1e12;
      }
    },
  },

  watch: {
    getUserAddress() {
      if (this.getUserAddress) this.readValues();
    },
    USDCAmount() {
      this.isUSDCApproved =
        Number(this.USDCAmount) <= Number(this.sixFormat(this.USDCAllowance));
    },
  },
  computed: {
    buttonText() {
      if (this.walletChainID == 369)
        return "WAATCA MINTING ONLY ON ETHEREUM";

      if (new Date().getTime() > this.mintDeadline * 1000) {
        return "Minting Period Has Ended";
      } else if (this.isUSDCApproved) {
          return "Mint WAATCA NFT";
      } else {
        return "Approve USDC";
      }
    },
  },
};
</script>
<style>
/*.v-main.WAATCA .v-main__wrap {*/
/*  background-image: url("../assets/waatca_nft_logo.png");*/
/*  background-size: 150%;*/
/*  background-position: center;*/
/*}*/
ol {
  text-align: left;
}

li {
  text-align: left;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

li:before {
  font-size: 2rem;
  color: #00ff00;
  font-weight: bold;
  margin-right: 10px;
  display: inline-block;
  counter-increment: item;
  content: counter(item);
}

@font-face {
  font-family: "DS-DIGI";
  src: url("~@/assets/fonts/ds_digital/DS-DIGIB.TTF");
}
</style>
