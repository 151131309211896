<template>
  <v-app id="inspire" :class="isLoading ? 'loading' : ''">
    <div class="main-loader" v-if="isLoading">
      <img src="@/assets/loader.webp" />
    </div>
    <v-navigation-drawer v-model="drawer" :width="300" app>
      <v-list nav>
        <v-list-item>
          <img
            src="@/assets/new_logo_lit.png"
            style="width: 100%; height: 100%; margin: auto"
          />
        </v-list-item>
        <v-list-item-group v-model="selectedItem" color="primary">
          <template v-for="(item, i) in items">
            <v-list-item
              :to="{ name: item.link }"
              @click="
                item.text === 'Carnival Lotteries'
                  ? (showSubLinks = !showSubLinks)
                  : (showSubLinks = false)
              "
              exact
              :key="i + 'link'"
              :disabled="
                (item.text === 'Carnival Lotteries' && walletChainID == 369) ||
                (item.text === 'Carnival Lotteries' && walletChainID == 943) ||
                item.disable
              "
            >
              <v-list-item-content>
                <img width="45px" :src="getImgUrl(item.icon)" />
              </v-list-item-content>
            </v-list-item>
            <template v-for="(sub, i) in item.subLinks">
              <template v-if="showSubLinks">
                <v-list-item
                  exact
                  :to="{ name: sub.link }"
                  :key="i + 'sublink'"
                  :disabled="sub.disable"
                >
                  <v-list-item-content>
                    <img width="25px" :src="getImgUrl(sub.icon)" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </template>
          <a
            :href="
              walletChainID == 1 || walletChainID == 5
                ? 'https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x488Db574C77dd27A07f9C97BAc673BC8E9fC6Bf3&chain=mainnet'
                : 'https://uniswap.hedron.pro/#/swap?outputCurrency=0x488Db574C77dd27A07f9C97BAc673BC8E9fC6Bf3'
            "
            style="text-decoration: none"
            target="_blank"
          >
            <v-list-item>
              <v-list-item-content>
                <img width="45px" :src="getImgUrl('lit_carnuniswap.png')" />
              </v-list-item-content>
            </v-list-item>
          </a>
          <a
            href="https://twitter.com/KB_Dev_Official"
            style="text-decoration: none"
            target="_blank"
          >
            <v-list-item>
              <v-list-item-content>
                <img width="45px" :src="getImgUrl('lit_twitter.png')" />
              </v-list-item-content>
            </v-list-item>
          </a>
          <a
            href="https://t.me/waatca"
            style="text-decoration: none"
            target="_blank"
          >
            <v-list-item>
              <v-list-item-content>
                <img width="45px" :src="getImgUrl('lit_telegram.png')" />
              </v-list-item-content>
            </v-list-item>
          </a>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon
        style="background-color: #faf9f6"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <h1 v-if="$route.name == 'Hex'">
        This is just a preview of whats to come
      </h1>
      <v-btn
        v-if="
          $route.name !== 'WhitePaper' &&
          $route.name !== 'Faq' &&
          $route.name !== 'PulseBitcoinFaucet' &&
          $route.name !== 'LotteriesHour' &&
          $route.name !== 'LotteriesWeek'
        "
        color="primary"
        class="mr-4"
      >
        <a :href="dynamicLink" target="_blank" class="white--text">
          Contract
        </a>
      </v-btn>
      <v-btn v-if="$route.name === 'WhitePaper'" color="primary" class="mr-4">
        <a
          class="white--text"
          href="./PulseDogecoin_Staking_Carnival_White_PaperV3.pdf"
          download="PulseDogecoin_Staking_Carnival_White_PaperV3.pdf"
        >
          <v-icon left> mdi-download </v-icon>
          Download
        </a>
      </v-btn>
      <v-btn
        v-if="getUserAddress"
        class="mr-4"
        color="primary"
        @click="switchChain"
      >
        {{
          walletChainID == 1
            ? "Switch to PulseChain"
            : walletChainID == 369
            ? "Switch to Ethereum"
            : walletChainID == 5
            ? "Switch to Testnet v4"
            : "Switch to Goerli Chain"
        }}
      </v-btn>
      <v-btn @click="onConnect" color="primary" :readonly="!!getUserAddress">
        {{ getUserAddress ? addrTruncation(getUserAddress) : "Connect Wallet" }}
      </v-btn>
    </v-app-bar>

    <v-main :class="$route.name">
      <div class="pa-5 pa-sm-8" style="height: 100%">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Web3 from "web3";
import Web3Modal from "web3modal";
import ABIS from "@/config/ABIS.json";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      drawer: null,
      showSubLinks: false,
      selectedItem: 0,
      items: [
        {
          text: "WAATCA",
          icon: "lit_waatca.png",
          link: "WAATCA",
          disable: false,
        },
        {
          text: "HEX Community Staker",
          icon: "lit_hex_staker.png",
          link: "Hex",
          disable: false,
        },
        {
          text: "HEX Rewards",
          icon: "lit_hex_rewards.png",
          link: "FreeHex",
          disable: false,
        },
        {
          text: "CARN Rewards",
          icon: "lit_carn.png",
          link: "FreeCarn",
          disable: false,
        },
        {
          text: "CARN Token Booth",
          icon: "lit_booth.png",
          link: "CarnTicketBooth",
          disable: false,
        },
        {
          text: "BUY & BURN",
          icon: "lit_buy_and_burn.png",
          link: "BuyAndBurn",
          disable: false,
        },

        {
          text: "Carnival Lotteries",
          icon: "lit_lottery_general.png",
          link: "Lotteries",
          subLinks: [
            {
              text: "Daily Lotteries",
              icon: "lit_daily.png",
              active: false,
              link: "LotteriesHour",
              disable: true,
            },
            {
              text: "Weekly Lotteries",
              icon: "lit_weekly.png",
              active: false,
              link: "LotteriesWeek",
              disable: true,
            },
            {
              text: "Monthly Lotteries",
              icon: "lit_monthly.png",
              active: false,
              link: "LotteriesMonth",
              disable: false,
            },
          ],
        },

        {
          text: "Stake PulseDogecoin",
          icon: "lit_stake_plsd.png",
          link: "StakePulseDogecoin",
          disable: false,
        },
        {
          text: "Community ASIC Miner",
          icon: "lit_asic_miner.png",
          link: "CommunityCarnivalAsicMiner",
          disable: false,
        },

        {
          text: "Pulse Bitcoin Lock",
          icon: "lit_pulsebitcoinlock.png",
          link: "PulseBitcoinLockApp",
          disable: true,
        },

        {
          text: "White Paper",
          icon: "lit_wp.png",
          link: "WhitePaper",
          disable: false,
        },
        { text: "F.A.Q.", icon: "lit_faq.png", link: "Faq", disable: false },
        {
          text: "Disclaimer",
          icon: "lit_disclaimer.png",
          link: "Disclaimer",
          disable: false,
        },
      ],
      provider: null,
      web3Modal: null,
      tokenBalance: 0,
      isAlreadyConnected: false,
      isCaptchaVerifed: false,
      sitekey: "6Ldv4KAjAAAAAJczfC6DGtN4s9qfgncrjCU2TUx0",
    };
  },
  //            1: "https://mainnet.infura.io/v3/",
  // 1: "http://localhost:8545",

  beforeMount() {
    const providerOptions = {
      walletconnect: {
        options: {
          rpc: {
            1: "https://mainnet.infura.io/v3/",
          },
          chainId: 1,
          network: "binance",
          infuraId: "2af64799935b4be086c072d13f0dad73",
        },
      },
    };

    this.web3Modal = new Web3Modal({
      providerOptions,
      cacheProvider: false,
      disableInjectedProvider: false,
    });
  },
  mounted() {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length) {
            this.provider = window.ethereum;
            this.onProvider();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  methods: {
    downloadPDF() {
      var link = document.createElement("a");
      link.href = "./PulseDogecoin_Staking_Carnival_White_PaperV3.pdf";
      link.download = "PulseDogecoin_Staking_Carnival_White_PaperV3.pdf";
    },
    getImgUrl(pet) {
      var images = require.context("./assets/", false, /\.png$/);
      return images("./" + pet);
    },
    async onConnect() {
      try {
        this.provider = await this.web3Modal.connect();
        this.onProvider();
        if (!this.isAlreadyConnected) {
          this.provider.on("accountsChanged", (accounts) => {
            console.log(accounts);
            this.onProvider();
          });

          this.provider.on("chainChanged", (chainId) => {
            console.log(chainId);
            this.onProvider();
          });
        }
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }
    },

    async onProvider() {
      this.isAlreadyConnected = true;
      let web3 = new Web3(this.provider);
      let accounts = await web3.eth.getAccounts();
      let id = await web3.eth.getChainId();
      if (this.CHAIN_ID.includes(Number(id))) {
        this.SET_WALLETCHAINID(Number(id));
      } else {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${this.CHAIN_ID[0].toString(16)}` }],
          });
          this.onProvider();
          return;
        } catch (switchError) {
          this.$toasted.show(
            `Only Ethereum ${this.NETWORK[0]} Network is supported`
          );
          return;
        }
      }

      let CARN_INSTANCE = new web3.eth.Contract(
        ABIS.CARN_ABI,
        this.CARN_ADDRESS
      );
      let PLSD_INSTANCE = new web3.eth.Contract(
        ABIS.PLSD_ABI,
        this.PLSD_ADDRESS
      );
      let PLSB_INSTANCE = new web3.eth.Contract(
        ABIS.PLSB_ABI,
        this.PLSB_ADDRESS
      );
      let ASIC_INSTANCE = new web3.eth.Contract(
        ABIS.ASIC_ABI,
        this.ASIC_ADDRESS
      );
      let USDC_INSTANCE = new web3.eth.Contract(
        ABIS.USDC_ABI,
        this.USDC_ADDRESS
      );
      let HEX_INSTANCE = new web3.eth.Contract(ABIS.HEX_ABI, this.HEX_ADDRESS);
      let CHAINLINK_INSTANCE = new web3.eth.Contract(
        ABIS.CHAINLINK_ABI,
        this.CHAINLINK_ADDRESS
      );
      let STAKING_INSTANCE = new web3.eth.Contract(
        ABIS.STAKING_ABI,
        this.STAKING_ADDRESS
      );
      let WAATCA_INSTANCE = new web3.eth.Contract(
        ABIS.WAATCA_ABI,
        this.WAATCA_ADDRESS
      );
      let NFT_REWARDS_INSTANCE = new web3.eth.Contract(
        ABIS.NFT_REWARDS_ABI,
        this.NFT_REWARDS_ADDRESS
      );
      let NFT_HEX_REWARDS_INSTANCE = new web3.eth.Contract(
        ABIS.NFT_HEX_REWARDS_ABI,
        this.NFT_HEX_REWARDS_ADDRESS
      );
      let BURN_INSTANCE = new web3.eth.Contract(
        ABIS.BURN_ABI,
        this.BURN_ADDRESS
      );
      let ASIC_MINER_INSTANCE = new web3.eth.Contract(
        ABIS.ASIC_MINER_ABI,
        this.ASIC_MINER_ADDRESS
      );
      let HEX_STAKER_INSTANCE = new web3.eth.Contract(
        ABIS.HEX_STAKER_ABI,
        this.HEX_STAKER_ADDRESS
      );

      let CARN_FAUCET_INSTANCE = new web3.eth.Contract(
        ABIS.CARN_FAUCET_ABI,
        this.CARN_FAUCET_ADDRESS
      );
      let PLSD_FAUCET_INSTANCE = new web3.eth.Contract(
        ABIS.PLSD_FAUCET_ABI,
        this.PLSD_FAUCET_ADDRESS
      );
      let PLSB_FAUCET_INSTANCE = new web3.eth.Contract(
        ABIS.PLSB_FAUCET_ABI,
        this.PLSB_FAUCET_ADDRESS
      );
      let ASIC_FAUCET_INSTANCE = new web3.eth.Contract(
        ABIS.ASIC_FAUCET_ABI,
        this.ASIC_FAUCET_ADDRESS
      );
      let USDC_FAUCET_INSTANCE = new web3.eth.Contract(
        ABIS.USDC_FAUCET_ABI,
        this.USDC_FAUCET_ADDRESS
      );
      let HEX_FAUCET_INSTANCE = new web3.eth.Contract(
        ABIS.HEX_FAUCET_ABI,
        this.HEX_FAUCET_ADDRESS
      );

      let TOKEN_INSTANCE = new web3.eth.Contract(
        ABIS.TOKEN_ABI,
        this.TOKEN_ADDRESS
      );

      let CONTRACT_INSTANCE = new web3.eth.Contract(
        ABIS.CONTRACT_ABI,
        this.CONTRACT_ADDRESS
      );

      this.SET_WEB3(web3);
      this.SET_USER_ADDRESS(accounts[0]);
      this.SET_CARN_INSTANCE(CARN_INSTANCE);
      this.SET_PLSD_INSTANCE(PLSD_INSTANCE);
      this.SET_PLSB_INSTANCE(PLSB_INSTANCE);
      this.SET_ASIC_INSTANCE(ASIC_INSTANCE);
      this.SET_USDC_INSTANCE(USDC_INSTANCE);
      this.SET_HEX_INSTANCE(HEX_INSTANCE);
      this.SET_CHAINLINK_INSTANCE(CHAINLINK_INSTANCE);
      this.SET_STAKING_INSTANCE(STAKING_INSTANCE);
      this.SET_WAATCA_INSTANCE(WAATCA_INSTANCE);
      this.SET_NFT_REWARDS_INSTANCE(NFT_REWARDS_INSTANCE);
      this.SET_NFT_HEX_REWARDS_INSTANCE(NFT_HEX_REWARDS_INSTANCE);
      this.SET_BURN_INSTANCE(BURN_INSTANCE);
      this.SET_ASIC_MINER_INSTANCE(ASIC_MINER_INSTANCE);
      this.SET_HEX_STAKER_INSTANCE(HEX_STAKER_INSTANCE);
      this.SET_CARN_FAUCET_INSTANCE(CARN_FAUCET_INSTANCE);
      this.SET_PLSD_FAUCET_INSTANCE(PLSD_FAUCET_INSTANCE);
      this.SET_PLSB_FAUCET_INSTANCE(PLSB_FAUCET_INSTANCE);
      this.SET_ASIC_FAUCET_INSTANCE(ASIC_FAUCET_INSTANCE);
      this.SET_USDC_FAUCET_INSTANCE(USDC_FAUCET_INSTANCE);
      this.SET_HEX_FAUCET_INSTANCE(HEX_FAUCET_INSTANCE);
      this.SET_TOKEN_INSTANCE(TOKEN_INSTANCE);
      this.SET_CONTRACT_INSTANCE(CONTRACT_INSTANCE);

      this.$toasted.show("Wallet Connected Successfully");
    },
    async switchChain() {
      let id = null;
      if (this.walletChainID === this.CHAIN_ID[0]) id = this.CHAIN_ID[1];
      else id = this.CHAIN_ID[0];
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${id.toString(16)}` }],
        });
        this.SET_WEB3(null);
        this.SET_USER_ADDRESS(null);
        this.onProvider();
      } catch (switchError) {
        switch (id) {
          case 369:
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${id.toString(16)}`,
                  rpcUrls: ["https://rpc.pulsechain.com"],
                  chainName: "PulseChain",
                  nativeCurrency: {
                    symbol: "PLS",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://scan.pulsechain.com"],
                },
              ],
            });
            this.SET_WEB3(null);
            this.SET_USER_ADDRESS(null);
            this.onProvider();
            break;
          case 943:
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${id.toString(16)}`,
                  rpcUrls: ["https://rpc.v4.testnet.pulsechain.com"],
                  chainName: "Testnet v4",
                  nativeCurrency: {
                    symbol: "tPLS",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://scan.v4.testnet.pulsechain.com"],
                },
              ],
            });
            this.SET_WEB3(null);
            this.SET_USER_ADDRESS(null);
            this.onProvider();
            break;
        }
      }
    },
  },
  computed: {
    ...mapGetters("wallet", ["isLoading"]),
    dynamicLink() {
      let url = "";
      switch (this.$route.name) {
        case "StakePulseDogecoin":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.STAKING_ADDRESS;
          break;
        case "CommunityCarnivalAsicMiner":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.ASIC_MINER_ADDRESS;
          break;
        case "FreeCarn":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.NFT_REWARDS_ADDRESS;
          break;
        case "FreeHex":
          url =
            this.ETHERSCAN_URL_BASE + "address/" + this.NFT_HEX_REWARDS_ADDRESS;
          break;
        case "Hex":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.HEX_STAKER_ADDRESS;
          break;
        case "CarnTicketBooth":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.CARN_ADDRESS;
          break;
        case "BuyAndBurn":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.BURN_ADDRESS;
          break;
        case "Lotteries":
          url = this.ETHERSCAN_URL_BASE + "address/";
          break;
        case "WAATCA":
          url = this.ETHERSCAN_URL_BASE + "address/" + this.WAATCA_ADDRESS;
      }
      return url;
    },
  },
};
</script>
<style>
@import "./styles/main.css";
</style>
